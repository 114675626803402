import React from 'react';
import PropTypes from 'prop-types';

const SelectOption = ({ identifier = 'name', ...rest }) => (
  <div className="select-option" data-select-option>
    <span>{rest[identifier]}</span>
  </div>
);

export default SelectOption;

SelectOption.propTypes = {
  identifier: PropTypes.string,
};
