import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import 'url-search-params-polyfill';

import BiometricsSelector from '../components/BiometricsSelector';
import BiometricsCard from '../components/BiometricsCard';
import BiometricTable from '../components/BiometricTable';

const BiometricsLayout = (props) => {
  const [rollup, setRollup] = useState(props.rollup);
  const [biometrics, setBiometrics] = useState(props.biometrics);
  const [record, setRecord] = useState(props.rollup || props.biometrics[0]);
  const [selectedProgramYear, setSelectedProgramYear] = useState(null);
  const [loading, setLoading] = useState(false);
  const [, setErrors] = useState(null);

  const findActivityDate = title => biometrics.find(b => b.metrics.find(m => m.title === title))?.activity_date;

  useEffect(() => {
    if (!selectedProgramYear) { return; }

    setErrors(null);
    setLoading(true);
    axios.get(`/biometrics/data?program_year_id=${selectedProgramYear.id}`)
      .then((response) => {
        setRollup(response.data.rollup);
        setRecord(response.data.rollup);
        setBiometrics(response.data.biometrics);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setErrors(error.response.data);
      });
  }, [selectedProgramYear]);

  return (
    <div>
      <div className="margin-bottom-sm d-flex align-items-start justify-content-between">
        <div className="col-4 p-0">
          {props.programYears.length > 0 && (
            <BiometricsSelector
              programYears={props.programYears}
              changeHandler={setSelectedProgramYear}
              loading={loading}
            />
          )}
          {biometrics.length > 0 && (
            <small className="col-12 ps-0 text-muted"><span>Last Screening on {biometrics[0].activity_date}</span></small>
          )}
        </div>

        <div className="d-print-none">
          <button type="button" className="btn btn-default btn-sm js-print-page">
            <i className="fa fa-print" /> Print
          </button>
        </div>
      </div>
      <div className="row margin-bottom">
        {record && record.metrics.filter(metric => metric.display_card).map(metric => (
          <div key={metric.title} className="col-md-4 col-sm-6" title="Biometrics Card">
            <BiometricsCard
              date={record.rollup ? findActivityDate(metric.title) : record.activity_date}
              {...metric}
            />
          </div>
        ))}
      </div>
      {biometrics.length > 0 ? (
        <div className="col-sm-12">
          <BiometricTable biometrics={rollup ? [rollup].concat(biometrics) : biometrics} />
        </div>
      ) : (
        <div>
          No biometric records present.
        </div>
      )}
    </div>
  );
};

export default BiometricsLayout;

BiometricsLayout.propTypes = {
  programYears: PropTypes.array.isRequired,
  biometrics: PropTypes.array.isRequired,
  rollup: PropTypes.object,
};

BiometricsLayout.defaultProps = {
  rollup: null,
};
