import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const BiometricTableCell = (props) => {
  const metric = props.biometric.metrics.find(m => m.title === props.metric.title);
  return (
    <td className={classNames('text-end', { 'bg-light': props.rollup })}>
      {metric && (
        <strong>
          {metric.stats.map(stat => (
            <span
              key={stat.label}
              className={`d-block margin-left-sm card-bm--${stat.risk || metric.risk}`}
              title={stat.label}
            >
              {stat.value}
            </span>
          ))}
        </strong>
      )}
    </td>
  );
};

export default BiometricTableCell;

BiometricTableCell.propTypes = {
  biometric: PropTypes.object.isRequired,
  metric: PropTypes.object.isRequired,
  rollup: PropTypes.bool,
};

BiometricTableCell.defaultProps = {
  rollup: false,
};
