/* eslint-disable jsx-a11y/click-events-have-key-events, react/state-in-constructor */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CardWrapper from './CardWrapper';

import './Card.scss';

export default class Card extends Component {
  state = { flipped: false }

  flipCard = () => {
    this.setState(prevState => ({ flipped: !prevState.flipped }));
  }

  renderHeader() {
    if (this.props.header && this.props.back) {
      return (
        <div className="card__header">
          <strong
            role="button"
            tabIndex="0"
            aria-pressed={this.state.flipped}
            onClick={this.flipCard}
            className="card__flip_trigger text-black"
          >
            More <i className="fa fa-info-circle" />
          </strong>
        </div>
      );
    }

    return null;
  }

  renderBackOfCard() {
    if (this.props.back) {
      return (
        <div className="card__back">
          <div className="card__header">
            <strong
              role="button"
              tabIndex="0"
              aria-pressed={this.state.flipped}
              onClick={this.flipCard}
              className="card__flip_trigger text-black"
            >
              Back
            </strong>
          </div>
          <div className="card__body">
            {this.props.back}
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    return (
      <CardWrapper flipped={this.state.flipped} height={this.props.height}>
        <div className="card__front">
          {this.renderHeader()}
          {this.props.front}
        </div>
        {this.renderBackOfCard()}
      </CardWrapper>
    );
  }
}

Card.propTypes = {
  front: PropTypes.element.isRequired,
  back: PropTypes.element,
  header: PropTypes.bool,
  height: PropTypes.string,
};

Card.defaultProps = {
  back: null,
  header: true,
  height: '400px',
};
