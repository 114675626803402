import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import SelectOption from 'src/common/SelectOption';

const BiometricsSelector = (props) => {
  const formatOptionLabel = option => <SelectOption {...option} name={`${option.start_date} - ${option.end_date}`} />;
  return (
    <div id="biometrics-selector" className="d-flex align-items-center p-0 col-12">
      <label htmlFor="program-year-select" className="visually-hidden">Program Year Select</label>
      <Select
        className="col-12 ps-0"
        inputId="program-year-select"
        options={props.programYears}
        defaultValue={props.programYears[0]}
        formatOptionLabel={formatOptionLabel}
        getOptionValue={option => option.id}
        getOptionLabel={option => `${option.start_date} - ${option.end_date}`}
        onChange={props.changeHandler}
      />

      {props.loading && (<small className="text-muted"><span>Loading...</span></small>)}
    </div>
  );
};

export default BiometricsSelector;

BiometricsSelector.propTypes = {
  programYears: PropTypes.array.isRequired,
  changeHandler: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
