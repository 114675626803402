/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Card from 'packs/common/Card';
import BiometricDescription from './BiometricDescription';

import './BiometricsCard.scss';

export default class BiometricsCard extends React.Component {
  flipCard = () => {
    this.card.flipCard();
  }

  hasDescription() {
    return !!this.props.description;
  }

  renderBackOfCard() {
    if (this.hasDescription()) {
      return (
        <BiometricDescription
          description={this.props.description}
          desirableRange={this.props.desirable_range}
          moreLink={this.props.more_link}
          date={this.props.date}
        />
      );
    }

    return null;
  }

  render() {
    return (
      <Card
        ref={(card) => { this.card = card; }}
        front={(
          <div className="card__body">
            <div className="card__media mt-2" style={{ backgroundImage: `url(${this.props.icon})` }} />
            {this.props.stats.map(key => (
              <div key={`${key.value}-${key.label}`} className={`card-bm__measurement card-bm--${this.props.risk}`}>
                <strong
                  className={classNames('card-bm__total', { 'card-bm__total--big': !key.label })}
                >
                  {key.value}
                </strong>
                {key.label && <strong className={`card-bm__label card-bm--${this.props.risk}`}>{key.label}</strong>}
              </div>
            ))}
            {this.props.unit && (
              <span className={`card-bm__unit card-bm--${this.props.risk}`}>
                ({this.props.unit})
              </span>
            )}
            <div className={`card__title u-sans-serif card-bm--${this.props.risk}`}>{this.props.title}</div>
            <div className={`card-bm__status u-sans-serif u-f7 card-bm--${this.props.risk}`}>
              {this.props.risk && (
                <span className="card__flip_trigger">
                  <strong
                    role="button"
                    tabIndex="0"
                    onClick={this.flipCard}
                    className="u-underline"
                  >
                    {this.props.risk} risk
                  </strong>
                </span>
              )}
              <div className="help-text">{this.props.date}</div>
            </div>
          </div>
        )}
        back={
          this.renderBackOfCard()
        }
      />
    );
  }
}

BiometricsCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  stats: PropTypes.array.isRequired,
  unit: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  description: PropTypes.string,
  risk: PropTypes.string,
  desirable_range: PropTypes.string,
  more_link: PropTypes.string,
};

BiometricsCard.defaultProps = {
  description: undefined,
  desirable_range: undefined,
  more_link: undefined,
  risk: undefined,
};
