import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';
import classNames from 'classnames';
import uniqBy from 'lodash/uniqBy';
import BiometricTableCell from './BiometricTableCell';

const BiometricTable = (props) => {
  const metrics = uniqBy(props.biometrics.map(bio => bio.metrics).flat(), metric => metric.title);
  const tooltip = description => description.replace(/(\S+\s*){1,10}/g, '$&<br />');

  return (
    <div>
      <h3>Past Biometric Data</h3>
      <span className="help-block">Hover over the icon for a description of the biometric.</span>
      <div className="table-responsive">
        <table className="table table-border table-hover">
          <thead>
            <tr>
              <th>Biometric</th>
              <th className="text-nowrap">Desirable Range</th>
              {props.biometrics.map(biometric => (
                <th
                  className={classNames('text-end text-nowrap', { 'bg-light': biometric.rollup })}
                  key={biometric.id}
                >
                  {biometric.rollup ? 'Most Recent Value' : biometric.activity_date}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {metrics.map(metric => (
              <tr key={metric.title}>
                <td>
                  <img className="me-1" src={metric.icon} height="25px" width="25px" alt="" data-tooltip-id={metric.title} data-tooltip-html={tooltip(metric.description)} />
                  <Tooltip id={metric.title} place="right" />

                  <strong>{metric.title}</strong>
                  {metric.unit && (
                    <span className="text-small">
                      &nbsp;({metric.unit})
                    </span>
                  )}
                </td>
                <td>{metric.desirable_range}</td>
                {props.biometrics.map(biometric => (
                  <BiometricTableCell
                    key={biometric.id}
                    biometric={biometric}
                    metric={metric}
                    rollup={biometric.rollup}
                  />
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BiometricTable;

BiometricTable.propTypes = {
  biometrics: PropTypes.array.isRequired,
};
