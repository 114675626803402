import React from 'react';
import PropTypes from 'prop-types';

const BiometricDescription = props => (
  <div>
    <p dangerouslySetInnerHTML={{ __html: props.description }} />

    <strong>Desirable Range: </strong>
    {props.desirableRange}

    {props.moreLink && (
    <div className="mt-3">
      <a href={props.moreLink} target="_blank" rel="noreferrer">Additional Information</a>
    </div>
    )}
  </div>
);

export default BiometricDescription;

BiometricDescription.propTypes = {
  description: PropTypes.string.isRequired,
  desirableRange: PropTypes.string.isRequired,
  moreLink: PropTypes.string.isRequired,
};
