/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import axios from 'axios';
import classNames from 'classnames';

const BiometricsOption = (props) => {
  const [biometricsOptionText, setBiometricsOptionText] = useState(props.optionText);
  const [biometricsExportable, setBiometricsExportable] = useState(props.userOption);

  const resetOptionText = () => {
    if (biometricsOptionText !== props.optionText) {
      setTimeout(() => setBiometricsOptionText(props.optionText), 5000);
    }
  };

  const handleInputChange = () => {
    axios.patch('/api/v2/user/privacy', { biometrics_exportable: !biometricsExportable })
      .then((response) => {
        setBiometricsExportable(response.data.biometrics_exportable);
        setBiometricsOptionText('Settings Successfully Updated');
      }).catch(() => {
        setBiometricsOptionText('Settings Update Failed');
      });
  };

  const displayDisclaimer = () => {
    const disclaimer = Swal.mixin({
      html: props.disclaimer,
    });

    disclaimer.fire({
      title: 'Biometric Data Disclaimer',
      width: '64rem',
    });
  };

  const hideLearnMore = () => {
    if (props.disclaimer === null) {
      return true;
    }

    return props.disclaimer.trim().length === 0;
  };

  useEffect(() => {
    resetOptionText();
  }, [biometricsOptionText]);

  return (
    <div className="form-group checkbox-group mb-3">
      <div className={classNames('checkbox checkbox-fancy d-flex', { 'align-items-center': hideLearnMore() })}>
        <label
          htmlFor="biometrics-exportable"
          className={classNames({ 'd-none': biometricsOptionText !== props.optionText })}
        >
          <input
            type="checkbox"
            id="biometrics-exportable"
            name="biometrics-exportable"
            checked={biometricsExportable}
            onChange={handleInputChange}
          />
          <div className="checkmark">
            <i className="fa fa-check fa-lg" />
          </div>
        </label>
        <div id="option-text">
          {biometricsOptionText}
          <button
            type="button"
            className={classNames('btn btn-link help-block p-0', { 'd-none': hideLearnMore() })}
            onClick={displayDisclaimer}
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default BiometricsOption;

BiometricsOption.propTypes = {
  optionText: PropTypes.string.isRequired,
  disclaimer: PropTypes.string,
  userOption: PropTypes.bool.isRequired,
};

BiometricsOption.defaultProps = {
  disclaimer: null,
};
