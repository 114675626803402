import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CardWrapper = props => (
  <div className={classNames('card', { 'card--flipcard': true })} style={{ height: props.height }}>
    <div className={classNames('card__wrapper', { flip: props.flipped })}>
      {props.children}
    </div>
  </div>
);

CardWrapper.propTypes = {
  flipped: PropTypes.bool,
  children: PropTypes.any.isRequired,
  height: PropTypes.string.isRequired,
};

CardWrapper.defaultProps = {
  flipped: undefined,
};

export default CardWrapper;
